.MyNavbar {
  .dropdown-menu {
    .nav-link {
      color: #212529 !important;

      &:hover {
        color: blue !important;
      }
    }
  }
}