.ShortMovie {
  margin: 10px 0;
  .movie-poster{
    max-height: 345px;
    min-height: 345px;
  }

  .card-title{
    height: 48px;
    overflow: hidden;
  }
}