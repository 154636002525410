.GooglePlacesInput{
  .search-bar-container {
    width: 90%;
    max-width: 500px;
    margin: 40px auto 0;
  }

  .search-input-container {
    position: relative;
  }

  .location-search-input,
  .location-search-input:focus,
  .location-search-input:active {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    border: honeydew;
    display: block;
    width: 100%;
    padding: 16px;
    font-size: 16px;
    border-radius: 2px;
    outline: none;
  }

  .Demo__clear-button,
  .Demo__clear-button:active,
  .Demo__clear-button:focus {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    color: #999;
  }

  .autocomplete-dropdown-container {
    border-bottom: honeydew;
    border-left: honeydew;
    border-right: honeydew;
    border-top: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    border-radius: 0 0 2px 2px;
  }

  .suggestion-item {
    padding: 8px;
    text-align: left;
    background-color: #fff;
    cursor: pointer;
  }

  .suggestion-item--active {
    background-color: #fafafa;
  }
}